footer {
  padding: 12px;
  @include extraSmallOnly() {
    padding: 8px;
    font-size: .8em;
  }
  text-align: center;
  background: $theme__footerBg;  
  &, a, a:hover {
    color: $theme__footerTxt;
  }
  transition: margin .4s;
  margin-bottom: $theme__headerAndFooterMargin;
  @media screen and (max-height: #{$theme__minHeightHaveHeaderAndFooterMargin}) {
    margin-bottom: 0;
  }
}

#policies {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.footer_cookiebot {
  text-align: center;
}
