.gigyaContainer {
  min-height: 500px;
}

.gigyaContainer__placeholder {
  min-height: 500px;
  color: $color__grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gigyaContainer--newPassword {
  .gigya-screen.portrait {
    width: auto!important;
  }
}
