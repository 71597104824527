header {
  background: $theme__headerBg;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height .4s, margin .4s;
  overflow: hidden;
  position: relative;
  margin-top: $theme__headerAndFooterMargin;
  text-align: center;
  @include extraSmallOnly() {
    margin-top: 0;
    margin-left: -1rem;
    margin-right: -1rem;
  }
  @media screen and (max-height: #{$theme__minHeightHaveHeaderAndFooterMargin}) {
    margin-top: 0;
  }
  h1 {
    position: absolute;
    margin-top: -400px;
  }
  img {
    max-height: 24px;
    max-width: 80%;
    width: auto;
    height: auto;
  }
}

.header--hideOnSmallScreens {
  @media screen and (max-height: #{$theme__minHeightToDisplayHeader}) {
    height: 0;
  }
}
