.neo__rules {

}

.neo__rules__inner {

}

.neo__rules__contentRow {
  @include neo__contentRow();
}

.neo__rules__textHolder {
  max-width: 600px;
  margin: 0 auto;

  p {
    margin: 22px 0;
  }
}
