$neo__enterCodeExpl__stepCol: #3CC1C8;
$neo__enterCodeExpl__loggedInInfoTxt: $neo__enterCodeExpl__stepCol;
$neo__enterCodeExpl__loggedInInfoBg: #F2F2F2;

.neo__enterCodeExpl {
  padding-bottom: .5px;
}

.neo__enterCodeExpl__inner {

}

.neo__enterCodeExpl__iosLinkHolder {
  width: 80%;
  max-width: 200px;
  position: relative;
  margin: 0 auto;
  a {
    cursor: pointer;
    transition: opacity .3s;
    &:hover {opacity: .75;}
  }
  img {
    max-width: 100%;
  }
}

.neo__enterCodeExpl__contentRow {
  @include neo__contentRow();
}

.neo__enterCodeExpl__steps {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  padding: 0 12px;
  > * {
    margin-bottom: 24px;
    &:last-child { margin-bottom: 0; }
  }
}

.neo__enterCodeExpl__step {
  padding-inline-start: 20px;
  @include smallUp() { padding-inline-end: 20px; }
  position: relative;
  h2 {    
    @include font__setBlack();
    > span {
      color: $neo__enterCodeExpl__stepCol;
    }
    &::before {
      content: "";
      display: inline-block;
      width: .7em;
      height: .7em;
      background: $neo__enterCodeExpl__stepCol;
      position: absolute;
      margin-inline-start: -20px;
      margin-top: 4px;
    }
  }
  p {}
}

.neo__enterCodeExpl__step__codeButtonHolder {
  text-align: center;
  > * {
    margin-bottom: 12px;
    &:last-child { margin-bottom: 0; }
    &:first-child { // button
      @include smallUp() {
        min-width: 320px;
      }
    }
  }
}

.neo__enterCodeExpl__step__loggedInInfo {
  background: $neo__enterCodeExpl__loggedInInfoBg;
  padding: 12px 36px;
  border-radius: 10px;
  position: relative;
  color: $neo__enterCodeExpl__loggedInInfoTxt;
  @include font__setStrong();
  &::before {
    content: "";
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translate(0, -50%);
    width: 20px;
    height: 20px;
    background: url("~@images/enterCode__loggedInMarker.png") center / contain no-repeat;
  }
}

.neo__enterCodeExpl__step__loginHolder {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    min-width: 220px;
  }
}

.neo__enterCodeExpl__step__notLoggedInInfo {
  text-align: center;
  font-style: italic;
  @include font__setBlack();
}
