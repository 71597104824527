.neo__completeProfile {
  a, a:hover, a:visited { color: $theme__link; }
}

.neo__completeProfile__inner {

}

.neo__completeProfile__contentRow {
  margin: 24px 0;
  @include smallUp() { margin: 32px 0; }
}

.neo__completeProfile__teaser {
  text-align: center;
  position: relative;
  img {
    width: 50%;
    max-width: 260px;
    height: auto;
  }
}

.neo__completeProfile__rewardsTitle {
  .h3 {
    @include font__setStrong();
    font-size: 1em;
    margin: 0;
  }
}

.neo__completeProfile__rewardsHolder {
  padding: 12px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, .2);
  max-width: 500px;
  width: 100%;
  position: relative;
  margin-inline-start: auto;
  margin-inline-end: auto;
  > * {
    margin-bottom: 12px;
    &:last-child { margin-bottom: 0; }
  }
}

ul.neo__completeProfile__rewards {
  padding: 0;
  margin: 0;
  li {
    margin: 4px 0;
    list-style-type: none;
    &::before {
      content: "";
      background: url("~@images/completeProfile__listIcon.png") center / contain no-repeat;
      width: 0;
      margin-inline-end: .5em;
      padding-left: 16px;
      height: 16px;
    }
  }
}

.neo__completeProfile__rewardDisclaimer {
  max-width: 476px;
  width: calc(100% - 24px);
  position: relative;
  margin: 12px auto;
  margin-bottom: 0;
  color: $theme__txt--light;
  font-size: .8em;
}

.neo__completeProfile__loginLink {
  @include font__setThin();
  text-decoration: underline;
}

.neo__completeProfile__actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
