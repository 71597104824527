$landing__bg: white;
$landing__actions__bg: #ebebeb;


.neo__landing {
  background: $landing__bg;
}

.neo__landing__inner {
  @include smallUp() {
    padding: 34px 12px;
  }
  @include extraSmallOnly() {
    padding: 20px 8px;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  > * {
    max-width: 100%;
  }
}

.neo__landing__infoForMobileUsers {
  margin-left: auto;
  margin-right: auto;
  max-width: 85%;
  text-align: center;
  @include font__setBlack();
}

img.neo__landing__teaser {
  width: 100%;
  height: auto;
}

@keyframes __ani__comingSoon__jiggle {
  0%    {  transform: rotate(-4deg); }
  0%    {  transform: rotate(-4deg); }
  80%   {  transform: rotate(-4deg); }
  85%   {  transform: rotate(-2deg); }
  90%   {  transform: rotate(-6deg); }
  95%   {  transform: rotate(-3deg); }
  100%  {  transform: rotate(-4deg); }
}

.neo__landing__comingSoon__holder {
  position: relative;
  height: 50px;
}

.neo__landing__desktopEnterCodesButtonHolder {
  position: relative;
  margin: 12px auto;
  text-align: center;
}

.neo__landing__androidActionsHolder {
  position: relative;
  margin: 12px auto;
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  max-width: 320px;

  > * {
    margin-bottom: 12px;
    &:last-child { margin-bottom: 0; }
  }

  img { width: 100%; height: auto; max-width: 200px; }
}

.neo__landing__iosActionsHolder {
  position: relative;
  margin: 12px auto;
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  max-width: 320px;
  > * {
    width: 100%;
    margin-bottom: 6px;
    &:last-child { margin-bottom: 0; }
  }
}

.neo__landing__comingSoon {
  z-index: 1;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  @include font__setStrong();
  @include smallUp() {
    font-size: 2.4em;
  }
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  > span {
    animation: __ani__comingSoon__jiggle 3s infinite both;
    display: inline-block;
    white-space: nowrap;
    @include extraSmallOnly() {
      padding: 2px 8px;
    }
    @include smallUp() {
      padding: 2px 24px;
    }
    background: rgba($landing__actions__bg, .8);
    border-radius: 4px;
  }
}

.neo__landing__teaser__holder {
  width: 100%;
  max-width: 600px;
  position: relative;
  margin-inline-start: auto;
  margin-inline-end: auto;
  .neo__landing__hiddenStuff {
    position: absolute;
    width: 6%;
    height: 10%;
    top: 33%;
    left: 65%;
  }
}

.neo__landing__actions {
  position: relative;
  background: $landing__actions__bg;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  @include extraSmallOnly() {
    border-radius: 12px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 8px;
    > * {
      flex-grow: 1;
    }
  }
  @include smallUp() {
    min-width: 80%;
    border-radius: 24px;
    padding: 18px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 8px;
    > * {
      flex-grow: 1;
      flex-shrink: 0;
      // min-width: 40%;
    }
  }
}

.neo__landing__infoBelowTeaser {
  text-align: center;
  margin: 0 auto;
  max-width: 600px;
  @include smallUp() {
    font-size: 1.1em;
  }
}

.neo__landing__teaserAsteriskExplanation {
  color: #767676;
  font-size: .8em;
  text-align: start;
  margin: 0 auto;
  margin-top: 10px;
  max-width: 800px;
}
