h2.neo__sectionTitle {
  @include font__setThin();
  text-transform: uppercase;
  text-align: center;  
  > span {
    position: relative;
    @include smallUp() {
      font-size: 1.4em;
    }
    > span {
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      height: .5em;
      width: 100%;
      width: calc(100% + 4px);
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
      background: $theme__titleUnderlineGradient;
    }
  }
}
