.game__viewContainer {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
}

.game__view {
  position: absolute;
  width: 100%;
  height: 100%;

  &--unityPlaceholder {
    display: none;
  }

  &--unity {
    &--hidden {
      // keep in DOM but hide
      top: -3000px;
    }
  }
}


.unity__container, .unity__loadingOverlay, .unity__instance {
  width: 100%;
  height: 100%;
}

.unity__container {
  position: relative;
}

.unity__loadingOverlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unity__loadingOverlay__percentage {
  font-size: 45px;
  font-weight: 300;
  color: $color__grey;
}
