@import "animate.css";

$transition__duration: 0.5s;
$transition__delay: 0.3s;

@mixin buildTransition($name, $aniIn, $aniOut) {
  .#{$name}-appear, .#{$name}-enter {
    z-index: 1;
    animation-duration: $transition__duration;
    animation-fill-mode: both;
  }
  .#{$name}-appear.#{$name}-appear-active, .#{$name}-enter.#{$name}-enter-active {
    animation-delay: $transition__delay;
    animation-name: $aniIn;
    animation-timing-function: ease-in;
  }
  .#{$name}-exit {
    animation-duration: $transition__duration;
    animation-fill-mode: both;
    animation-timing-function: ease-in;
  }
  .#{$name}-exit.#{$name}-exit-active {
    animation-name: $aniOut;
    animation-timing-function: ease-out;
  }
}

// @include buildTransition("fadeLeft", fadeInLeft, fadeOutLeft);
// @include buildTransition("slideLeft", slideInLeft, slideOutLeft);
// @include buildTransition("slideUp", slideInUp, slideOutUp);
@include buildTransition("slideVertical", slideInDown, slideOutUp);
@include buildTransition("slideVertical--reverse", slideInDown, slideOutUp);
@include buildTransition("slideHorizontal", slideInRight, slideOutLeft);
@include buildTransition("slideHorizontal--reverse", slideInLeft, slideOutRight);
