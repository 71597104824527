.browser {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.browser__header {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $theme__headerGradient;
  &, a, a:hover {
    color: white;
  }
  gap: 8px;
  transition: padding .3s;
  padding: 0 12px;
  @include extraSmallOnly() { padding: 0 8px; }
}

.browser__frameHolder{
  flex: 1;
  display: flex;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.browser__frame{
  flex: 1;
  border: 0;
  width: 100%;
  height: 100%;
}

a.browser__backLink {
  cursor: pointer;
  overflow: hidden;
  > span { position: absolute; margin-top: -200px; }
  display: inline-block;
  width: 32px;
  height: 38px;
  background: url("~@images/header__iconBack.png") center / contain no-repeat;
  transition: transform .2s;
  &:hover { transform: scale(1.1); }
}

.browser__header__actionArea {
  flex-grow: 0;
  flex-shrink: 0;
  width: 32px;
}

.browser__header__titleArea {
  h2 {
    text-align: center;
    text-transform: uppercase;
    @include font__setThin();  
  }
}