.neo__browserNotSupported {

}

.neo__browserNotSupported__inner {

}

.neo__browserNotSupported__contentRow {
  @include neo__contentRow();
}

.neo__browserNotSupported__panel {
  max-width: 600px;
  @include neo__panel;
  margin: 0 auto;

  p {
    margin: 22px 0;
  }
}

.neo__browserNotSupported__browsers {
  margin: 8px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
  position: relative;
  > * {
    width: 25%;
    @include extraSmallOnly() { width: 40%; }
  }
}

a.neo__browserNotSupported__browser {
  display: inline-block;
  position: relative;
  text-align: center;
  text-decoration: none;
  img {
    height: 100px;
    width: auto;
    @include extraSmallOnly() { height: 80px; }
  }
  h3 {
    @include font__setThin();
    margin: 0;
    @include smallUp() { font-size: 1.1em; }
  }
  &:hover h3 {text-decoration: underline;}
  span {
    color: $color__txt--light;
    @include font__setThin();
    font-size: .8em;
  }
}

.neo__browserNotSupported__info {
  text-align: center;
}

.neo__browserNotSupported__enterCodeInfo__info {
  margin: 12px auto;
  text-align: center;
  @include font__setStrong();
}

.neo__browserNotSupported__appLinks {
  margin: 12px 0;
  @include smallUp() {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    img {
      height: 48px;
      width: auto;
    }
  }
  @include extraSmallOnly() {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;    
    img {
      width: 180px;
      height: auto;
    }
  }
}

.neo__browserNotSupported__enterCodeInfo__actionHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  > * {
    min-width: 50%;
  }
}