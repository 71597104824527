.applinks {
  background: $theme__footerBg;
  padding: 16px 0;
  border-bottom: 2px solid $theme__footerBorder;
  @include smallUp() {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  @include extraSmallOnly() {
    display: none; // hide on phones -> landingpage will habe download buttons as well!
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // gap: 8px;
  }
}

.applinks__link {
  > img {
    height: 50px;
    width: auto;
    @include extraSmallOnly() { height: 38px; }
  }
}

a.applinks__link { cursor: pointer; }
span.applinks__link { opacity: .75; cursor: not-allowed; }