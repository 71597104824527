@mixin neo__contentRow {
  margin: 32px 0;
  @include smallUp() { margin: 48px 0; }
}

@mixin neo__panel {
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .3);
  padding: 18px;
  @include extraSmallOnly() { padding: 12px; }
  > * {
    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
  }
}
