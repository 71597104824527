@mixin extraSmallOnly {
  @media screen and (max-width: 599px) {
    @content;
  }
}

@mixin smallUp {
  @media screen and (min-width: 600px) {
    @content;
  }
}

@mixin smallOnly {
  @media screen and (min-width: 600px) and (max-width: 899px) {
    @content;
  }
}

@mixin mediumUp {
  @media screen and (min-width: 900px) {
    @content;
  }
}

@mixin mediumOnly {
  @media screen  and (min-width: 900px) and (max-width: 1199px) {
    @content;
  }
}

@mixin largeUp {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin printOnly {
  @media print {
    @content;
  }
}
