$neo__enterCodeRedeem__stickerNumCol: #3CC1C8;
$neo__enterCodeRedeem__belowInfoCol: #646464;
$neo__enterCodeRedeem__errorCol: #D6095B;
$neo__enterCodeRedeem__inputBorderCol: #129B9D;
$neo__enterCodeRedeem__packAspectRatio: 0.88; // 76 / 86

.neo__enterCodeRedeem {
  padding-bottom: .5px;
}

.neo__enterCodeRedeem__inner {
  padding-bottom: 5em;
}

.neo__enterCodeRedeem__info {
  text-align: center;
}

.neo__enterCodeRedeem__formHolder {
  margin: 20px 0;
  form {
    max-width: 280px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    > * {
      width: 100%;
    }
  }
}

.neo__enterCodeRedeem__inputTitle {
  @include font__setThin();
  text-align: center;
  text-transform: uppercase;
  color: $neo__enterCodeRedeem__belowInfoCol;
}

.neo__enterCodeRedeem__error {
  text-align: center;
  font-size: .9em;
  color: $neo__enterCodeRedeem__errorCol;
}

.neo__enterCodeRedeem__input {
  border: 1px solid $neo__enterCodeRedeem__inputBorderCol;
  border-radius: 6px;
  font-size: 1.1em;
  line-height: 2em;
  text-align: center;
  &::placeholder {
    color: $neo__enterCodeRedeem__belowInfoCol;
    opacity: .5;
  }
}
.neo__enterCodeRedeem__input--errored {
  border-color: $neo__enterCodeRedeem__errorCol;
}

.neo__enterCodeRedeem__belowInfo {
  text-align: center;
  color: $neo__enterCodeRedeem__belowInfoCol;
  font-size: .8em;
  @include font__setThin();
}

.neo__enterCodeRedeem__panel {
  @include neo__panel();
  max-width: 460px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.neo__enterCodeRedeem__contentRow {
  @include neo__contentRow();
}

.neo__enterCodeRed__popup__holder {
  position: fixed;
  flex-direction: column;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes __ani__neo__enterCodePopupBgIn {
  from  { opacity: 0; }
  to    { opacity: 1; }
}

@keyframes __ani__neo__enterCodePopupCloseIn {
  from  { opacity: 0; }
  to    { opacity: 1; }
}

@keyframes __ani__neo__enterCodePopupContentIn {
  0%    { opacity: .4; transform: scale(.8); }
  70%   { opacity: 1; transform: scale(1.04); }
  100%  { opacity: 1; transform: scale(1); }
}

.neo__enterCodeRed__popup__title {
  margin-bottom: 1.5em;
  h2 {
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    @include font__setStrong();
    @include extraSmallOnly() { font-size: 1.05em; }
    @include smallUp() { font-size: 1.5em; }
    > span {
      position: relative;
      > span {
        position: relative;
        z-index: 1;
      }
      &::after {
        content: "";
        position: absolute;
        z-index: 0;
        height: .5em;
        width: 100%;
        width: calc(100% + 4px);
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        background: $theme__titleUnderlineGradient;
      }
    }
  }
}

.neo__enterCodeRed__popup__packs__content {
  > * {
    margin-bottom: 12px;
    &:last-child { margin-bottom: 0; }
  }
}

.neo__enterCodeRed__popup__coins__content {
  > * {
    margin-bottom: 12px;
    &:last-child { margin-bottom: 0; }
  }
}

@keyframes __ani__neo__enterCodeCoinsIn {
  from  { transform: scale(.6); }
  to    { transform: scale(1); }
}

.neo__enterCodeRed__popup__coins__rewardImg {
  position: relative;
  padding: 14px;
  transition: height .3s;
  @include smallUp { height: 350px; }
  background: url("~@images/enterCode__rewardDeco.png") center / contain no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    animation: __ani__neo__enterCodeCoinsIn .4s ease-out both;
    transition: height .3s;
    width: 70%;
    max-width: 240px;
    height: auto;
  }
}

.neo__enterCodeRed__popup__coins__total {
  text-align: center;
  @include font__setStrong();
}

.neo__enterCodeRed__popup__packs__imagesWrapper {
  width: 100%;
  --height: 200px;
  @include smallUp() { --height: 300px; }
  background: url("~@images/enterCode__rewardDeco.png") center / contain no-repeat;
  height: 200px;
  height: var(--height);
  padding-top: 60%;
  position: relative;
  transition: height .3s;
  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(var(--height) * #{$neo__enterCodeRedeem__packAspectRatio});
    height: var(--height);
    transform: translate(-50%, -50%);
  }
}
.neo__enterCodeRed__popup__packs__imageHolder {
  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
  }
}
.neo__enterCodeRed__popup__packs__name {
  color: $neo__enterCodeRedeem__belowInfoCol;
  text-align: center;
}
.neo__enterCodeRed__popup__packs__openHint {
  @include font__setStrong();
  text-align: center;
}

.neo__enterCodeRed__popup__packs__openHint__image__holder {

}

.neo__enterCodeRed__popup__packs__openHint__image {
  width: 80%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .4);
  max-width: 280px;
  position: relative;
  margin: 0 auto;
  > img {
    width: 100%;
    height: auto;
  }
}

.neo__enterCodeRed__popup__packs__openHint__image__pack {
  position: absolute;
  bottom: 11%;
  padding-top: calc(16% / #{$neo__enterCodeRedeem__packAspectRatio});
  width: 16%;
  right: 31%;
  background-position: center;
  background-size: 100% 100%;
  transform: rotate(3deg);
  box-shadow: 0 3px 6px rgba(0, 0, 0, .3);
}

.neo__enterCodeRed__popup__packs__openHint__image__arrow {
  top: -15%;
  right: 41%;
  position: absolute;
  width: 12%;
  padding-top: 20%;
  background: url("~@images/enterCode__openPacksInfoArrow.png") center / contain no-repeat;
}

.neo__enterCodeRed__popup__packs__openHint__image__cover {
  position: absolute;
  top: 0;
  padding-top: 5%;
  left: 5%;
  right: 5%;
  background-position: bottom center;
  background-size: 100% auto;
}

.neo__enterCodeRed__popup__closeHolder {
  width: calc(100% - 12px);
  max-width: 520px;
  position: relative;
  height: 0;
  z-index: 11;
  > a {
    animation: __ani__neo__enterCodePopupCloseIn .2s ease-in both;
    cursor: pointer;
    position: absolute;
    bottom: -20px;
    right: -4px;
    @include smallUp() {
      bottom: -20px;
      right: -20px;
    }
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .25);
    background: white url("@images/enterCode__popupCloseIcon.png") center / 45% no-repeat;
    transform: scale(1);
    transition: transform .2s, bottom .2s, right .2s;
    &:hover {
      transform: scale(1.2) rotate(2deg);
    }
  }
}

.neo__enterCodeRed__popup__bg {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 0;
  background: rgba(0, 0, 0, .15);
  animation: __ani__neo__enterCodePopupBgIn .2s ease-in both;
}

.neo__enterCodeRed__popup__content {
  animation: __ani__neo__enterCodePopupContentIn .4s ease-in both;
  position: relative;
  z-index: 1;
  max-height: 80%;
  width: calc(100% - 12px);
  max-width: 520px;
  @include neo__panel();
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.neo__enterCodeRed__popup__stickers__content {
  > * {
    margin-bottom: 12px;
    &:last-child { margin-bottom: 0; }
  }
}

.neo__enterCodeRed__popup__stickers__entries {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 18px 8px;
  > * {
    width: calc(50% - 8.5px);
  }
}
.neo__enterCodeRed__popup__stickers__entries--singleSticker {
  > * {
    width: 70%;
    @include extraSmallOnly() { width: 90%; }
  }
}

.neo__enterCodeRed__popup__stickers__entry {
  position: relative;
  h3 {
    text-align: center;
    @include extraSmallOnly() { font-size: .8em; }
    margin: 4px 0;
    span { // number
      color: $neo__enterCodeRedeem__stickerNumCol;
    }
  }
}

.neo__enterCodeRed__popup__stickers__entry__img {
  width: 100%;
  padding-top: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.neo__enterCodeRed__popup__stickers__alreadyOwnedIcon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  background: url("~@images/enterCode__newStickerMarker.png") center / contain no-repeat;
}

.neo__enterCodeRed__popup__stickers__newInfo {
  font-size: .8em;
  margin-top: 2em;
  margin-bottom: 0;
  text-align: center;
  color: $color__txt--light;
}

.neo__enterCodeRed__popup__requiresCollection__content {
  > * {
    margin-bottom: 12px;
    &:last-child { margin-bottom: 0; }
  }
}

.neo__enterCodeRed__popup__requiresCollection__entries {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 18px 8px;
  > * {
    width: calc(50% - 8.5px);
  }
}

.neo__enterCodeRed__popup__requiresCollection__entry {
  display: block;
  position: relative;
  h3 {
    text-align: center;
    @include extraSmallOnly() { font-size: .8em; }
    margin: 4px 0;
    span { // number
      color: $neo__enterCodeRedeem__stickerNumCol;
    }
  }
  &:hover {
    text-decoration: none;
  }
}

.neo__enterCodeRed__popup__requiresCollection__entry__img {
  width: 100%;
  padding-top: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}