@keyframes __ani__ec__stickerSelectCheckmark__in {
  from  { opacity: .6; transform: scale(1.6) translate(0, -50%); }
  to    { opacity: 1; transform: scale(1) translate(0); }
}

.neo__ec__pageWrapper {
  position: relative;
}

.neo__ec__pageWrapper--hideDuringPayment {
  display: none;
}

.neo__ec__info {
  text-align: center;
  margin-top: 30px;
}

.neo__ec__row {
  margin: 24px 0;
  @include smallUp() { margin: 32px 0; }
}

.neo__ec__panel {
  position: relative;
}
.neo__ec__panel--box {
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .3);
  padding: 18px;
  padding-top: 1px;
  padding-bottom: 1px;
  @include extraSmallOnly() { padding-inline-start: 12px; padding-inline-end: 12px; }
  > * {
    &:first-child { margin-top: 0; }
    &:last-child { margin-bottom: 0; }
  }
}
.neo__ec__panel--transparent {

}
.neo__ec__panel--fullContent { padding: 0; position: relative; }
.neo__ec__panel--slim {
  width: 420px;
  max-width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
}
.neo__ec__panel--full {
  max-width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.neo__ec__shopItem {
  background: linear-gradient(#fdfdfd, #fbfbfb);
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 8px;
  padding: 4px;
  @include smallUp() { gap: 16px; }
}
.neo__ec__shopItem__below {
  background: #f0f0f0;
  position: relative;
  padding: 8px 12px;
}
.neo__ec__shopItem__imgHolder {
  width: 200px;
  max-width: 22%;
  min-width: 62px;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
}
.neo__ec__shopItem__imgHolder--unavailable {
  opacity: .45;
}
.neo__ec__shopItem__outOfStock {
  color: $theme__error;
  display: inline-block;
  margin-top: .5em;
  @include font__setStrong();
}
.neo__ec__shopItem__comingSoon {
  position: relative;
  > span {
    padding: 4px 12px;
    color: white;
    background: $theme__link;
    display: inline-block;
    margin-top: .5em;
    @include font__setStrong();
    transform: rotate(-3deg);
  }
}
.neo__ec__shopItem__textHolder {
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  h3 {
    margin: 4px 0;
    @include font__setStrong();
  }
  p {
    margin: 4px 0;
    font-size: .8em;
  }
}

.neo__ec__itemList {
  width: 420px;
  max-width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .3);
  > * {
    border-bottom: 1px solid #f0f0f0;
    &:last-child { border-bottom: 0; }
  }
}

.neo__ec__itemList__emptyNote {
  padding: 34px 12px;
  text-align: center;
  font-size: .8em;
  color: $theme__txt--light;
  @include font__setThin();
}

.neo__ec__itemList__sectionHolder {
  padding: 8px 4px 6px 4px;
}

@keyframes __ani__ec__busy__spin {
  from  { transform: rotate(0); }
  to    { transform: rotate(360deg); }
}

@keyframes __ani__ec__busy__in {
  from  { opacity: 0; }
  to    { opacity: 1; }
}

.neo__ec__busyOverlay {
  z-index: 99;
  animation: __ani__ec__busy__in 1.5s;
  animation-fill-mode: both;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, .2);
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    width: 48px; height: 48px;
    @include smallUp() { width: 80px; height: 80px; }
    background: url("~@images/ec__busy.png") center / contain no-repeat;
    animation: __ani__ec__busy__spin 1.2s linear infinite both;
  }
}

@keyframes __ani__ec__errorPopup__allIn {
  from  { opacity: 0; }
  to    { opacity: 1; }
}

@keyframes __ani__ec__errorOverlay__popupIn {
  from  { opacity: .8; transform: scale(.8); }
  to    { opacity: 1; transform: scale(1); }
}

@mixin neo__errorBoxStyle {
  background: white;
  border: 1px solid lighten($theme__error, 10%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, .3);
  padding: 18px;
  @include extraSmallOnly() { padding: 12px; }
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  gap: 12px;
  > * { margin: 0; }
}

.neo__ec__displayError__overlay {
  z-index: 99;
  animation: __ani__ec__errorOverlay__allIn .5s;
  animation-fill-mode: both;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, .2);
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    animation: __ani__ec__errorOverlay__popupIn .5s;
    width: 80%;
    @include neo__errorBoxStyle();
    max-width: 340px;
    min-height: 120px;
  }
}

.neo__ec__confirmAction {
  text-align: center;
}
.neo__ec__confirmAction__askingHolder {

}
.neo__ec__confirmAction__confirmQuestion {
  margin-bottom: 8px;
}
.neo__ec__confirmAction__confirmOptions {
  display: flex;
  justify-content: center;
  gap: 22px;
  align-items: center;  
}
.neo__ec__confirmAction__actionHolder {

}

.neo__ec__sectionTitle {
  text-align: center;
  > span {
    position: relative;
    > span {
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      z-index: 0;
      height: .2em;
      width: 100%;
      width: calc(100% + 4px);
      left: 50%;
      bottom: 2px;
      transform: translate(-50%, 0);
      background: $theme__titleUnderlineGradient;
    }
  }
}

.neo__ec__restrictedToCountriesList {
  color: $theme__txt--light;
  font-size: .8em;
}

.neo__ec__actionsHolder {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  gap: 8px;
  position: relative;
  max-width: 280px;
  margin: 0 auto;
  > * {
    flex-grow: 1;
  }
}
.neo__ec__amountInfo {
  h4 { @include font__setThin(); }
}
.neo__ec__amountInfo__stickersList {
  @include font__setThin();
  color: $theme__txt--light;
  font-size: .8em;
}
.neo__ec__amountInfo__amountAndPrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.neo__ec__amountInfo__priceHolder {
  text-align: end;
}
.neo__ec__amountInfo__amountInputHolder {
  margin-top: 4px;
  padding-top: 4px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include extraSmallOnly() { flex-direction: column; text-align: center; }
  input {
    max-width: 80px;
    line-height: 1.2em;
  }
}

.neo__ec__textInput {
  line-height: 1.6em;
  padding: 4px;
  font-size: 1em;
  @include font__setNormal();
  border: 1px solid #bbb;
  border-radius: 4px;
}
select.neo__ec__textInput {
  background: #fafafa;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 35px;
}

.neo__ec__actionLinkHolder {
  text-align: center;
}

.neo__ec__shortAddress {
  background: linear-gradient(#fdfdfd, #fbfbfb);
  padding: 8px 12px;
  > *:first-child { @include font__setStrong(); margin-bottom: 4px; }
}

.neo__ec__shortAddress__below {
  background: #f0f0f0;
  position: relative;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
  > * { min-width: 60%; }
}

.neo__ec__changeAddressesLinkHolder {
  text-align: center;
  font-size: .8em;
  padding: 8px 4px;
}

.neo__ec__buttonSubinfo {
  text-align: center;
  font-size: .8em;
  margin-bottom: 6px;
}

.neo__ec__costSegment {
  display: flex;
  padding: 8px 4px;
  justify-content: space-between;
  align-items: center;
  @include extraSmallOnly() { font-size: .8em; }
  > *:first-child {
    
  }
  > *:last-child {
    text-align: end;
  }
}

.neo__ec__order__orderNumber {
  margin-top: .5em;
  font-size: 1.4em;
  @include extraSmallOnly() { font-size: 1.2em; }
  color: #666;
  padding: .2em .4em;
  border: 1px solid $theme__footerBg;
  background: lighten($theme__footerBg, 5%);
  border-radius: 4px;
  @include font__setThin();
  font-variant-numeric: tabular-nums;
}

.neo__ec__order__inlineLabelAndValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  gap: 8px;
  padding: 4px 12px;
  > *:first-child { @include font__setStrong(); display: flex; align-items: center; min-height: 32px; }
  @include extraSmallOnly() {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: .8em;
    gap: 0;
  }
}

.neo__ec__order__val {
  min-height: 40px;
  padding: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.neo__ec__coupon {
  display: flex;
  padding: 8px 4px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  @include extraSmallOnly() { font-size: .8em; }
  a { text-align: center; }
  > *:first-child {
    
  }
  > *:last-child {    
    flex-shrink: 1;
    text-align: end;
    position: relative;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 6px;
    span { @include font__setThin(); word-break: break-all; }
  }
  input { width: 100%; max-width: 120px; }
  @include extraSmallOnly() {
    &.neo__ec__coupon--notApplied {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    &.neo__ec__coupon--applied {
      > *:last-child {
        text-align: end;
        display: block;
        > * { display: block; text-align: end; }
      }
    }
  }
}

.neo__ec__selectStickersWrapper {
  background: #f8f8f8;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  > * {
    width: calc(100% / 2);
    @include smallUp() { width: calc(100% / 4); }
    @include mediumUp() { width: calc(100% / 6); }
    @include largeUp() { width: calc(100% / 8); }
  }
}

.neo__ec__selectStickersWrapper--slim {
  > * {
    width: calc(100% / 2);
    @include smallUp() { width: calc(100% / 3); }
  }
}

.neo__ec__sticker {
  position: relative;
  padding: 6px;
  h3 {
    @include font__setThin();
    font-size: .8em;
    text-align: center;
    margin: 0;
    margin-bottom: 2px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    padding-inline-start: 4px;
    padding-inline-end: 4px;
    > span {
      // flex-grow: 1;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    > strong {
      flex-shrink: 0;
      @include font__setNormal();
      margin-inline-start: .25em;
      color: $theme__link;
    }
  }
}
.neo__ec__sticker__inner {
  position: relative;
}
.neo__ec__sticker__img {
  height: 0;
  padding-top: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.neo__ec__sticker--blockedForSelection {
  opacity: .4;
}
.neo__ec__sticker--selected {
  > * {
    box-shadow: 0 0 4px $theme__link;
  }
}
.neo__ec__sticker--notSelected {}

.neo__ec__sticker__pickPlaceholder,
.neo__ec__sticker__pick {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-top: -12px;
  height: 40px;  
  > * {
    min-width: 34px;
    border-radius: 20px;
    background: white;
    display: flex;
    align-items: stretch;
    justify-content: center;
    box-shadow: 0 3px 4px rgba(0, 0, 0, .2);
    > * {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > strong {
      font-size: .8em;
      @include font__setThin();
      width: auto;
    }
    > a {
      user-select: none;
      padding: 0 8px;
      font-size: 32px;
      @include font__setStrong();
      &, &:hover { text-decoration: none; }
      &:hover { opacity: .6; }
    }
  }
  .neo__ec__sticker__pick__singleWrapper {
    background: $theme__buttonGradient;
    > a {
      animation: __ani__ec__stickerSelectCheckmark__in .2s ease-out both;
      background: url("~@images/ec__stickerSelectedMarker.png") center / 75% auto no-repeat;      
      width: 40px;
      height: 28px;
      flex-grow: 0;
      flex-shrink: 0;
      align-self: center;
    }
  }
}
.neo__ec__sticker__pickPlaceholder {
  visibility: hidden;
  opacity: 0;
}
.neo__ec__sticker__pick__singleUnselectHolder {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2px;
  right: -4px;
  padding: 2px 4px;
  border-radius: 10px;
  background: $theme__footerBg;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  > a {
    &, &:hover, &:active {
      color: $theme__txt;
      text-decoration: none;
    }
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 16px;    
    white-space: nowrap;
    text-align: right;
    font-size: .7em;
    padding: 2px 20px 2px 2px;
    background: url("~@images/ec__stickerUnselect.png") right center / auto 80% no-repeat;
    &:hover {
      opacity: .5;
    }
  }
}

.neo__ec__addressForm__errorDesc {
  padding: 8px;
  color: $theme__error;
  @include font__setThin();  
  border: 1px solid lighten($theme__error, 10%);
}

.neo__ec__addressForm__info {
  text-align: center;
  font-size: .8em;
}

.neo__ec__formRow { margin: 22px 0; }
.neo__ec__formRow--tinyMargin { margin: 4px 0; }

.neo__ec__formHolder { padding: 1px; }

.neo__ec__addressForm__labelAndInput {
  > label {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    gap: 4px;
    position: relative;
    > span:first-child {
      text-align: center;
      @include font__setThin();
    }
    > div {
      display: flex;
      justify-content: stretch;
      align-items: stretch;
      > * { flex-grow: 1; flex-shrink: 1; }
    }
  }
}

.neo__ec__addressForm__labelAndInput--withError {
  input.neo__ec__textInput,
  select.neo__ec__textInput {
    border-color: $theme__error;
  }
}

.neo__ec__addressForm__waitPlaceholder {
  text-align: center;
  @include font__setThin();
  font-size: .8em;
}

.neo__ec__priceValue {
  white-space: nowrap;
  font-variant-numeric: tabular-nums;
}

.neo__ec__dedicatedOrder__error {
  color: $theme__error;
  margin: 3em auto;
  width: 90%;
  max-width: 340px;
  @include neo__errorBoxStyle();
}

.neo__ec__orders__none {
  display: flex;
  min-height: 80px;
  justify-content: center;
  align-items: center;
  color: $theme__txt--light;
  font-size: .8em;
  @include font__setThin();
}

.neo__ec__orders__entry {
  background: linear-gradient(#fdfdfd, #fbfbfb);
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: stretch;
  padding: 8px 12px;

  h3 {
    color: #666;
    padding: .2em .4em;
    border: 1px solid $theme__footerBg;
    background: lighten($theme__footerBg, 5%);
    border-radius: 4px;
    @include font__setThin();
    font-variant-numeric: tabular-nums;  
  }
  > div:first-child { // info
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
    > * { margin: 0; }
  }
  > div:last-child { // action
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.neo__ec__orders__description {
  @include font__setStrong();
}
.neo__ec__orders__date {
  font-size: .8em;
  @include font__setThin();
}

.neo__ec__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 44px;
}

.neo__ec__pagination--current {
  color: $theme__txt--light;
  opacity: .5;
}

.neo__ec__paymentLegalInfo {
  line-height: 1.2em;
  font-size: .8em;
}
.neo__ec__paymentLegalInfo__creditCardInfo {
}
.neo__ec__paymentLegalInfo__checkHolder {
  > label{
    display: flex;
    gap: 12px;
    align-items: flex-start;
    > span:first-child {
      margin-inline-start: .1em;
      flex-grow: 1;
      > input[type=checkbox] {
      }
    }
    > span:last-child { // label text

    }
  }
}
.neo__ec__paymentLegalInfo__mandatoryInfo {
  color: $theme__txt--light;
  font-style: italic;
}
.neo__ec__paymentLegalInfo__infoProceeding {
}

.neo__ec__floatingContinueButton {
  position: sticky;
  bottom: 0;
  padding-bottom: 1.5em;
  padding-top: 6px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: rgba(255, 255, 255, .8);
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

// HOTFIX: Hide "Save for my next payment"
#__adyen-dropin-container .adyen-checkout__dropin .adyen-checkout__payment-method__details__content .adyen-checkout__store-details {  
  display: none;
}