$accountActivated__bg: white;

.neo__accountActivated {
  background: $accountActivated__bg;
  min-height: 300px;
}

.neo__accountActivated__inner {
  @include smallUp() {
    padding: 34px 12px;
  }
  @include extraSmallOnly() {
    padding: 20px 8px;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  > * {
    max-width: 100%;
  }
}
