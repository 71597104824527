.neo__button {
  display: inline-flex;
  justify-content: stretch;
  align-items: stretch;
  font-size: .8em;
  min-width: 100px;
  min-height: 40px;
  border-radius: 9px;
  padding: 5px;
  background: $theme__buttonBorder;
  @include font__setStrong();
  text-transform: uppercase;
  text-align: center;
  user-select: none;
}

.neo__button__inner {
  flex-grow: 1;
  box-shadow: 0 0 6px rgba(0, 0, 0, .4);
  border-radius: 5px;
  background: $theme__buttonGradient;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  &, &:hover {
    color: white;
    text-decoration: none;
  }
}

a.neo__button:hover {
  cursor: pointer;
  text-decoration: none;
  background: $theme__buttonBorder--hover;
  .neo__button__inner {
    background: $theme__buttonGradient--hover;
  }
}

span.neo__button {
  cursor: not-allowed;
  opacity: .4;
}

.neo__button--enterCodes {
  height: 60px;
  border-radius: 30px;
  .neo__button__inner {
    border-radius: 25px;
    &::before {
      content: "";
      width: 60px;
      height: 40px;
      margin-inline-end: .5em;      
      @include extraSmallOnly() {
        width: 44px;
        height: 40px;
        margin-inline-end: .2em;
      }
      display: inline-block;
      background: url("~@images/enterCode__icon.png") center / contain no-repeat;
    }
  }
}

.neo__landing__buttonIOSGetGame {
  height: 80px;
  border-radius: 40px;
  .neo__button__inner {
    border-radius: 35px;
  }
}
.neo__landing__buttonIOSGetGame__content {
  text-align: center;
  position: relative;
  > span { display: block; margin-bottom: 4px; }
  > img { max-width: 120px; width: 100%; height: auto; }
}