$theme__footerBg: #EBEBEB;
$theme__footerBorder: #D4D4D4;
$theme__footerTxt: #0A2D52;
$theme__headerBg: #1c4759;
$theme__headerGradient: linear-gradient(#254658, #346e72);
$theme__titleUnderlineGradient: linear-gradient(90deg, #CDEBFD, #BFFDF5);
$theme__minHeightToDisplayHeader: 700px;
$theme__minHeightHaveHeaderAndFooterMargin: 750px;
$theme__headerAndFooterMargin: 20px;
$theme__buttonGradient: linear-gradient(#51a6a9, #458e94);
$theme__buttonBorder: #408295;
$theme__buttonGradient--hover: linear-gradient(#60c1c4, #53a8ae);
$theme__buttonBorder--hover: #4b96af;
$theme__txt: #000000;
$theme__link: #14A6A8;
$theme__txt--light: #6F6D6D;
$theme__error: #E52138;
