html {
  font-size: 62.5%;
  line-height: 1.15;
  text-size-adjust: 100%;
}

body {
  @include font__setNormal();
  font-size: var(--font__size);
}

h1, h2, h3, h4, h5, h6 {
  @include font__setStrong();
  margin: 0.35rem 0 0.7rem;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.85rem;
}

strong, em {
  @include font__setStrong();
}

a {
  cursor: pointer;
  &, &:hover, &:active {
    color: var(--color__primary);
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
}


dl dt {
  font-weight: bold;
}

