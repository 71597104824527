@import "normalize.css";

@import "@style/_defaults";
@import "@style/_theme";
@import "@style/_fonts";
@import "@style/_typography";
@import "@style/_base";
@import "@style/_media_queries";
@import "@style/_helper";
@import "@style/_transitions";

html, body {
  padding: 0;
  margin: 0;
  outline: 0;
  position: relative;
  min-height: 100%;
}

@import "@style/layout/_main";
@import "@style/layout/_html";
@import "@style/layout/_grid";
@import "@style/layout/_body";
@import "@style/layout/_footer";
@import "@style/layout/_applinks";
@import "@style/layout/_header";
@import "@style/components/_button";
@import "@style/components/_logout_button";
@import "@style/components/_browser";
@import "@style/components/_section_title";

@import "@style/pages/_game";
@import "@style/pages/_landing";
@import "@style/pages/_rules";
@import "@style/pages/_browserNotSupported";
@import "@style/pages/_completeProfile";
@import "@style/pages/_accountActivated";
@import "@style/pages/_ecommerce";
@import "@style/pages/_sandbox";
@import "@style/pages/_enterCodeExpl";
@import "@style/pages/_enterCodeRedeem";

@import "@style/gigya/_new_password";
