.neo__logoutButtonHolder {  
  position: relative;
  margin: 18px 0;
  margin-top: 62px;
  padding-top: 18px;
  text-align: center;
  > * {
    min-width: 180px;
  }
  &::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    left: 25%;
    top: 0;
    background: #eee;
  }
}
