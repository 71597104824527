.container {
  max-width: var(--grid__maxWidth);
  margin: 0 auto;
  width: 96%;
  padding: 0 calc(var(--grid__gutter) / 2);
}

.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-left: calc(var(--grid__gutter) / -2);
  margin-right: calc(var(--grid__gutter) / -2);
}

.row.reverse {
  flex-direction: row-reverse;
}

.col {
  flex: 1;
}

.col,
[class*=" col-"],
[class^="col-"] {
  margin: 0 calc(var(--grid__gutter) / 2) calc(var(--grid__gutter) / 2);
}

@for $i from 1 through 12 {
  .col-#{$i} {
    flex: 0 0 calc((100% / (12/$i)) - var(--grid__gutter));
    max-width: calc((100% / (12/$i)) - var(--grid__gutter));
  }
}

@include extraSmallOnly {
  .container {
    width: 100%;
  }

  .col,
  [class*="col-"],
  [class^="col-"] {
    flex: 0 1 100%;
    max-width: 100%;
  }
}

@include smallUp {
  @for $i from 1 through 12 {
    .col-#{$i}-md {
      flex: 0 0 calc((100% / (12/$i)) - var(--grid__gutter));
      max-width: calc((100% / (12/$i)) - var(--grid__gutter));
    }
  }
}

@include mediumUp {
  @for $i from 1 through 12 {
    .col-#{$i}-lg {
      flex: 0 0 calc((100% / (12/$i)) - var(--grid__gutter));
      max-width: calc((100% / (12/$i)) - var(--grid__gutter));
    }
  }
}
