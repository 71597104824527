hr {
  border: none;
  background-color: var(--color__lightGrey);
  height: 1px;
  margin: 1rem 0;
}


p {
  margin-top: 0;
}
